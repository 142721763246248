import {useQuery} from "@tanstack/react-query";
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import {APP_API} from "./App";

function Boards() {
    const {getIdToken} = useKindeAuth()
    return useQuery({
        queryKey: ['board'], queryFn: async () => {
            const authToken = await getIdToken();
            if (!authToken) {
                return {data: []}
            }
            const fetchData = await fetch(`${APP_API}/api/board`, {
                headers: {
                    'Authorization': authToken
                }
            });
            if (!fetchData.ok) {
                return {data: []}
            }
            return await fetchData.json();
        }
    });
}

export default Boards;